import React from "react";
import styles from './Hero.module.scss';
import { ENTRY_DEADLINE, EVENT_DATE, EVENT_NAME, EVENT_SLOGAN, EVENT_VENUE } from "../../Constants/constants";
import Button from "../Button";
import Message from "../Message";

function Hero() {
  return (
    <section className={styles.hero}>
      <div className="container">
      <div className={styles.hero__wrapper}>
        <div className={styles.hero__content}>

          {/* <Message type={'warning'} className={`${styles.hero__message}`}>
            <strong>Entry Deadline:</strong> {ENTRY_DEADLINE}
          </Message> */}

          <h1 className='sr-only'>{EVENT_NAME}</h1>
          <div className={styles.hero__title}>{EVENT_SLOGAN}</div>
          <p className={styles.hero__details}><span className={styles.hero__date}>{EVENT_DATE}</span> <span className={styles.separator}>|</span> <span className={styles.hero__venue}>{EVENT_VENUE}</span> | Entries opening soon</p>
          <Button to={'/winners'}>Winners 2024</Button>
          <Button to={'/gallery'} filled={false}>Gallery</Button>
        </div>
      </div>
      </div>
    </section>
  );
}

export default Hero;

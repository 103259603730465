import React, { useState, useEffect } from "react";
import { EVENT_YEAR, PREVIOUS_EVENT_YEAR } from "../../Constants/constants";
import styles from "./Sponsors.module.scss";

const Sponsors = ({ sponsors, currentYear = false, className }) => {
  // Set up state to store the sorted and grouped sponsors
  const [sortedSponsors, setSortedSponsors] = useState([]);

  useEffect(() => {
    const sorted = sponsors
      .map((sponsorGroup) => ({
        order: sponsorGroup.order,
        type: sponsorGroup.type,
        sponsors: sponsorGroup.sponsors.sort((sponsorA, sponsorB) =>
          sponsorA.name.localeCompare(sponsorB.name)
        ),
      }))
      .sort((groupA, groupB) => groupA.order - groupB.order);

    setSortedSponsors(sorted);
  }, [sponsors]);

  const renderGroup = (groupType, gridSize, imageClass) => (
    <div id='sponsors' key={groupType.type} className={`${styles.sponsor__group} ${styles.gridSize}`}>
      <h3 className={styles.sponsor__group__title}>{groupType.type}</h3>
      <div className={styles.sponsor__group__logos}>
        {groupType.sponsors.map((sponsor) => (
          <div key={sponsor.name} className={styles.sponsor}>
            <a href={sponsor.link} className={styles.sponsor__link}>
              <img
                src={`${process.env.PUBLIC_URL}/img/sponsors/${sponsor.image}`}
                alt={`${sponsor.name} ${styles.logo}`}
                className={`${styles.sponsor__image} ${imageClass}`}
                loading='lazy'
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section id='sponsors' className={`${styles.sponsors} ${className ? className : ""}`}>
      {currentYear && (
        <h2 className={styles.sponsors__title}>{EVENT_YEAR} Sponsors</h2>
      )}
      {!currentYear && (
        <h2 className={styles.sponsors__title}>{PREVIOUS_EVENT_YEAR} Sponsors</h2>
      )}

      {sortedSponsors.map((group, index) => {
        const sponsorCount = group.sponsors.length;
        let imageClass = "sponsor__image--size-4";
        let gridSize = "sponsor__group--size-12";

        if (sponsorCount % 4 === 0) {
          imageClass = "sponsor__image--size-3";
        } else if (
          sponsorCount % 3 === 0 ||
          sponsorCount === 1 ||
          sponsorCount === 5
        ) {
          gridSize = "sponsor__group--size-9";
        }

        if (
          group.type === "Lanyard" ||
          group.type === "Sustainability Partner"
        ) {
          if (group.type === "Lanyard") {
            return (
              <div
                key='lanyard-sustainability-wrapper'
                className={`${styles.sponsor__group_wrapper} ${styles.gridSize}`}>
                {renderGroup(group, "", imageClass)}
                {renderGroup(sortedSponsors[index + 1], "", imageClass)}
              </div>
            );
          } else {
            return null;
          }
        } else {
          return renderGroup(group, gridSize, imageClass);
        }
      })}
    </section>
  );
};

export default Sponsors;

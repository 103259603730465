export const data = [
  {
    order: 1,
    topic: "Reseller",
    summary:
      "The Reseller Awards are for third party solution providers (MSP's, systems integrators, resellers, dealers) and recognise the effectiveness, quality and ROI of solutions provided to end user customers.",
    categories: [
      {
        id: "best_enterprise_uc_customer_solution",
        subcategory: "UC Solution Category",
        name: "Best Enterprise UC Customer Solution",
        summary:
          "The award recognises reseller unified communications solutions that have increased customer productivity, improved efficiencies, and reduced costs in enterprise private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/gamma.webp",
        form: '../../../forms/best_enterprise_uc_customer_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Cloud Geeks",
              "Dataquest Group",
              "F One Technologies",
              "Global 4 Communications",
              "Uplands OneTelco",
              "Windsor Telecom"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Windsor Telecom",
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "F one Technologies",
            ]
          }
        ]
      },
      {
        id: "best_sme_uc_customer_solution",
        subcategory: "UC Solution Category",
        name: "Best SME UC Customer Solution",
        summary:
          "The award recognises reseller unified communications solutions that have increased customer productivity, improved efficiencies, and reduced costs in SME private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/gamma.webp",
        form: '../../forms/best_sme_uc_customer_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Alfonica",
              "Chrome Telecom",
              "Dataquest Group",
              "Fibre 1",
              "Global 4 Communications",
              "K5 Communications",
              "Onecom",
              "Opus Technology",
              "Premier Voice Data",
              "Start Communications"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Cloud Geeks",
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Global4",
            ]
          }
        ]
      },
      {
        id: "best_enterprise_mobile_iot_customer_solution",
        subcategory: "Mobile/IoT Category",
        name: "Best Enterprise Mobile/IoT Customer Solution",
        summary:
          "The award recognises reseller mobile or IOT solutions that have increased customer productivity, improved efficiencies, and reduced costs in enterprise private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/jola.webp",
        form: '../../forms/best_enterprise_mobile_or_iot_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Barclay Digital Services",
              "Cellhire",
              "Global 4 Communications",
              "Opus Technology",
              "Pure Cloud Solutions",
              "Rydal Group",
              "Uplands OneTelco",
              "Windsor Telecom"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Uplands OneTelco"
              
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Windsor Telecom",
            ]
          }
        ]
      },
      {
        id: "best_sme_mobile_iot_customer_solution",
        subcategory: "Mobile/IoT Category",
        name: "Best SME Mobile/IoT Customer Solution",
        summary:
          "The award recognises reseller mobile or IOT solutions that have increased customer productivity, improved efficiencies and reduced costs in small to medium sized private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/jola.webp",
        form: '../../forms/best_sme_mobile_or_iot_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Alfonica",
              "Cellhire",
              "Global 4 Communications",
              "K5 Communications",
              "Opus Technology",
              "Spitfire Network Services",
              "Uplands OneTelco"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Spitfire Network Services"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Uplands OneTelco",
            ]
          }
        ]
      },
      {
        id: "best_enterprise_vertical_market_customer_solution",
        subcategory: "Vertical Market Category",
        name: "Best Enterprise Vertical Market Customer Solution",
        summary:
          "The award recognises reseller solutions in specific vertical markets (e.g., finance, health, retail, local government, sport and recreation etc.) that have increased customer productivity, improved efficiencies and reduced costs in enterprise sized private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "",
        form: '../../forms/best_enterprise_vertical_market_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Global 4 Communications",
              "Opus Technology",
              "SCG Together"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Global 4"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "SCG Together",
            ]
          }
        ]
      },
      {
        id: "best_sme_vertical_market_customer_solution",
        subcategory: "Vertical Market Category",
        name: "Best SME Vertical Market Customer Solution",
        summary:
          "The award recognises reseller solutions in specific vertical markets (e.g., finance, health, retail, local government, sport and recreation etc.) that have increased customer productivity, improved efficiencies and reduced costs in SME sized private or public-sector organisations.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/voiceflex.webp",
        form: '../../forms/best_sme_vertical_market_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "3B Data Security",
              "Alfonica",
              "ANS",
              "Chrome Telecom",
              "Evolve B G",
              "Future Voice",
              "Opus Technology",
              "X-on Health"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Future Voice"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "X-on Health",
            ]
          }
        ]
      },
      {
        id: "best_enterprise_managed_service_customer_solution",
        subcategory: "Managed Services Category",
        name: "Best Enterprise Managed Service Customer Solution",
        summary:
          "This award is aimed at reseller/MSP businesses that have provided a compelling managed service solution for Enterprise end user customers.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/xelion.webp",
        form: '../../forms/best_enterprise_managed_services__solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Digital Space Group",
              "Forge Technologies",
              "Hughes Europe",
              "Pure Cloud Solutions",
              "Rydal Group",
              "Uplands OneTelco",
              "Windsor Telecom"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Windsor Telecom"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Uplands OneTelco",
            ]
          }
        ]
      },
      {
        id: "best_sme_managed_service_customer_solution",
        subcategory: "Managed Services Category",
        name: "Best SME Managed Service Solution",
        summary:
          "This award is aimed at reseller/MSP businesses that have provided a compelling managed service solution for SME end user customers.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/xelion.webp",
        form: '../../forms/best_sme_managed_services_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Cisilion",
              "Cloud Geeks",
              "Evolve B G",
              "Global 4 Communications",
              "Hughes Europe",
              "Uptech",
              "V4 Cloud",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Cloud Geeks"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Global 4",
            ]
          }
        ]
      },
      {
        id: "best_enterprise_contact_centre_customer_solution",
        subcategory: "Contact Centre Category",
        name: "Best Enterprise Contact Centre Customer Solution",
        summary:
          "This award is aimed at reseller businesses that have provided a compelling contact centre solution for enterprise end user customers in the UK private or public sector.",
        sponsor__title: "Sponsored By",
        sponsor: "",
        form: '../../forms/best_enterprise_contact_centre_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Cisilion",
              "Future Voice",
              "Onecom",
              "Opus Technology",
              "Windsor Telecom",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Future Voice"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Onecom",
            ]
          }
        ]
      },
      {
        id: "best_sme_contact_centre_customer_solution",
        subcategory: "Contact Centre Category",
        name: "Best SME Contact Centre Customer Solution",
        summary:
          "This award is aimed at reseller businesses that have provided a compelling contact centre solution for SME end user customers in the UK private or public sector.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/telxl.webp",
        form: '../../forms/best_sme_contact_centre_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Onecom",
              "Opus Technology",
              "Start Communications"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Onecom"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Start Communications",
            ]
          }
        ]
      },
    ],
  },
  {
    order: 2,
    topic: "Vendor",
    summary:
      "The Vendor awards category celebrates the suppliers, carriers, network providers, distributors and service providers who supply the best products, software, services and support to their reseller partners.",
    categories: [
      {
        id: "best_call_management_solution",
        subcategory: "Software Solution Category",
        name: "Best Call Management Solution",
        summary:
          "This award will go to the provider of the best call management system or solution that has helped resellers add value to customer communications or contact centre estates.",
        sponsor__title: "",
        sponsor: "",
        form: '../../forms/best_call_management_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "CallCabinet",
              "Callroute",
              "Oak Innovation",
              "Tollring",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Oak Innovation"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Tollring",
            ]
          }
        ]
      },
      {
        id: "best_billing_solution",
        subcategory: "Software Solution Category",
        name: "Best Billing Solution",
        summary:
          "This award will go to the best software solution that has helped resellers bill and provision telecoms and data services effectively and efficiently and improve their customer management processes.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_billing_solution.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Giacom",
              "Inform Billing",
              "PRD Technologies",
              "Tekton Billing",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Tekton Billing"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Inform Billing",
            ]
          }
        ]
      },
      {
        id: "best_ict_distributor_above",
        subcategory: "Distribution Category",
        name: "Best ICT Distributor",
        summary:
          "This award will recognise distributors of communications hardware and/or software with a turnover above £10m. These companies will have demonstrated excellence in helping value added resellers grow their businesses through a range of quality logistical and support services.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_ict_distributor_above_£10m.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Mdee",
              "Nuvias UC",
              "ProVu Communications",
              "Viegli",
              "Westcon-Comstor",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "ProVu Communications"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Nuvias UC",
            ]
          }
        ]
      },
      // {
      //   id: "best_ict_distributor_above",
      //   subcategory: "Distribution Category",
      //   name: "Best ICT Distributor Above £10m",
      //   summary:
      //     "This award will recognise distributors of communications hardware and/or software with a turnover above £10m. These companies will have demonstrated excellence in helping value added resellers grow their businesses through a range of quality logistical and support services.",
      //   sponsor__title: "",
      //   sponsor: "",
      //   form: '../../../forms/best_ict_distributor_above_£10m.docx',
      //   finalists: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Mdee",
      //         "Nuvias UC",
      //         "ProVu Communications",
      //         "Viegli",
      //         "Westcon-Comstor",
      //       ]
      //     }
      //   ],
      //   highly__commended: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Rachel",
      //         "Simon",
      //         "Heather"
      //       ]
      //     }
      //   ],
      //   winner: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Michael",
      //       ]
      //     }
      //   ]
      // },
      // {
      //   id: "best_ict_distributor_below",
      //   subcategory: "Distribution Category",
      //   name: "Best ICT Distributor Up to £10m",
      //   summary:
      //     "This award will recognise distributors of communications hardware and/or software with a turnover up to £10m. These companies will have demonstrated excellence in helping value added resellers grow their businesses through a range of quality logistical and support services.",
      //   sponsor__title: "",
      //   sponsor: "",
      //   form: '../../../forms/best_ict_distributor_up_to_£10m.docx',
      //   finalists: [
      //     {
      //       year: "2024",
      //       company: [
      //         ""
      //       ]
      //     }
      //   ],
      //   highly__commended: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Rachel",
      //         "Simon",
      //         "Heather"
      //       ]
      //     }
      //   ],
      //   winner: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Michael",
      //       ]
      //     }
      //   ]
      // },
      {
        id: "best_ucaas_vendor",
        subcategory: "UC & Collaboration Category",
        name: "Best UCaaS Vendor",
        summary:
          "This award will go to the supplier of UCaaS solutions, marketed through channel partners, that enable business customer communications and collaboration by integrating multiple communications methods (e.g., phone calls, video conferencing, instant messaging, email, SMS, fax etc.). Solutions will include hardware endpoints that enrich the UC experience including video conferencing equipment, UC headsets, executive phones etc.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_ucaas_vendor.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "8x8",
              "Byphone",
              "CallSwitch One",
              "Evolve IP",
              "Gamma",
              "Intermedia Cloud Communications",
              "Jabra",
              "NTA",
              "TSI",
              "Voiceflex",
              "Wildix",
              "Xelion"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Evolve IP",
              "Byphone"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Voiceflex",
            ]
          }
        ]
      },
      {
        id: "uc_collaboration_category",
        subcategory: "UC & Collaboration Category",
        name: "Best CCaaS Vendor",
        summary:
          "This award will go to the supplier of Cloud based CCaaS solutions, marketed through channel partners, that allows organisations to provide customers with an omnichannel experience including analytics and reporting insights, IVR and customisable self-service capabilities, skills-based routing for improved contact resolution and improved contact centre performance via a flexible low cost OPEX hosted solution.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_ccaas_vendor.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "8x8",
              "Cirrus",
              "Gamma",
              "Intermedia Cloud Communications",
              "TelXL",
              "Xelion",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Xelion"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Intermedia Cloud Communications",
            ]
          }
        ]
      },
      {
        id: "best_network",
        subcategory: "Intelligent Network & Connectivity Category",
        name: "Best Network Service Provider",
        summary:
          "This award will recognise fixed line, mobile carriers or network providers offering connectivity solutions to UK business and homes across the UK in partnership with resellers.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_network_services_provider.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "All Points Fibre Networks",
              "Aryaka Networks",
              "BT Wholesale",
              "ITS Technology Group",
              "MS3 Networks",
              "Pangea Connected",
              "PXC",
              "Spitfire Network Services",
              "Vodafone Business",
              "Vorboss"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "MS3 Networks"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Pangea Connected",
            ]
          }
        ]
      },
      {
        id: "best_altnet",
        subcategory: "Intelligent Network & Connectivity Category",
        name: "Best Altnet",
        summary:
          "This award will recognise fixed line, mobile carriers or network providers offering connectivity solutions to UK business and homes across the UK in partnership with resellers.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_altnet.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "G.Network Communications",
              "MS3 Networks"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "G.Networks"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "MS3 Networks",
            ]
          }
        ]
      },
      {
        id: "best_wholesale_service_provider_up_to_10m_fixed_mobile_cloud",
        subcategory: "Service Provider Category",
        name: "Best Wholesale Service Provider (up to £10m)",
        summary:
          "This award will acknowledge wholesale service providers, aggregators and ISPs that have most effectively helped resellers deliver network solutions to channel customers.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_wholesale_service_provider_up_to_£10m.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Eclipse Wholesale",
              "MS3 Networks",
              "NTA",
              "Pangea Connected",
              "TSI",
              "Voiceflex",
              "Voip Unlimited",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Pangea Connected"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Voip Unlimited",
            ]
          }
        ]
      },
      {
        id: "best_wholesale_service_provider_above_10m_fixed_mobile_cloud",
        subcategory: "Service Provider Category",
        name: "Best Wholesale Service Provider (above £10m)",
        summary:
          "This award will acknowledge wholesale service providers, aggregators and ISPs that have most effectively helped resellers deliver network solutions to channel customers.",
        sponsor__title: "",
        sponsor: "",
        form: '../../../forms/best_wholesale_service_provider_above_£10m.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "All Points Fibre Networks",
              "Cellhire",
              "Evolve IP",
              "Gamma",
              "Giacom",
              "Jola",
              "PXC",
              "Sky Business Wholesale",
              "Spitfire Network Services",
              "Zest4",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Spitfire Network Services"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Giacom",
            ]
          }
        ]
      },
    ],
  },

  {
    order: 3,
    topic: "Business",
    summary:
      "The Business Awards category celebrates vendors, suppliers, distributors, service providers and resellers by recognising growth, success, innovation and business culture.",
    categories: [
      // {
      //   id: "business_transformation_award_award_enterprise_reseller",
      //   subcategory: "Business Success Category",
      //   name: "Business Transformation Award Enterprise Reseller",
      //   summary:
      //     "",
      //   sponsor__title: "",
      //   sponsor: null,
      //   form: '../../../forms/business_transformation_award_enterprise_reseller.docx',
      //   finalists: [
      //     {
      //       year: "2024",
      //       company: [
      //         ""
              
      //       ]
      //     }
      //   ],
      //   highly__commended: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Rachel",
      //         "Simon",
      //         "Heather"
      //       ]
      //     }
      //   ],
      //   winner: [
      //     {
      //       year: "2024",
      //       company: [
      //         "Michael",
      //       ]
      //     }
      //   ]
      // },
      {
        id: "business_transformation_award_sme_reseller",
        subcategory: "Business Success Category",
        name: "Reseller Business Transformation Award",
        summary:
          "",
        sponsor__title: "",
        sponsor: "../../../../img/sponsors/tsi.webp",
        form: '../../../forms/business_transformation_award_sme_reseller.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Britannic",
              "Core Technology Systems",
              "Fidelity Group",
              "Redsquid",
              "Square One Network",
              "Telecom Acquisitions",
              "Windsor Telecom",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Square One Network"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Core Technology Systems",
            ]
          }
        ]
      },
      {
        id: "enterprise_reseller_deal_of_the_year",
        subcategory: "Business Success Category",
        name: "Enterprise Reseller Deal of the Year",
        summary:
          "This award is aimed at SME reseller businesses that have pitched for and won a private or public sector deal that has given a massive boost to their business in terms of revenue, staff security and morale and has provided a platform for further growth.",
        sponsor__title: "",
        sponsor: "../../../../img/sponsors/ipnetix.webp",
        form: '../../../forms/enterprise_reseller_deal_of_the_year_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Focus Group",
              "Opus Technology",
              "Windsor Telecom"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Focus Group"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Windsor Telecom",
            ]
          }
        ]
      },
      {
        id: "sme_reseller_deal_of_the_year",
        subcategory: "Business Success Category",
        name: "SME Reseller Deal of the Year",
        summary:
          "This award is aimed at SME reseller businesses that have pitched for and won a private or public sector deal that has given a massive boost to their business in terms of revenue, staff security and morale and has provided a platform for further growth.",
        sponsor__title: "",
        sponsor: "../../../../img/sponsors/ipnetix.webp",
        form: '../../../forms/sme_reseller_deal_of_the_year_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Cloud Geeks",
              "Pure Cloud Solutions",
              "Redsquid"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Redsquid"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Cloud Geeks",
            ]
          }
        ]
      },
      {
        id: "best_partner_programme_up_to_10m",
        subcategory: "Channel Support Category",
        name: "Best Partner Programme (up to £10m)",
        summary:
          "This award will be won by a vendor, carrier, distributor, or service provider which has delivered a top-notch channel partner programme combining high-end touchpoints, training, portals, marketing campaigns etc. which have increased the status, value and growth prospects of resellers.",
        sponsor__title: "",
        sponsor: null,
        form: '../../../forms/best_partner_programme_up_to_£10m.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "ANS",
              "Byphone",
              "Eclipse Wholesale",
              "Fidelity Group",
              "Luminate Wholesale",
              "MS3 Networks",
              "NTA",
              "Pangea Connected",
              "Rydal Group",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Pangea Connected"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "MS3 Networks",
            ]
          }
        ]
      },
      {
        id: "best_partner_programme_above_10m",
        subcategory: "Channel Support Category",
        name: "Best Partner Programme (above £10m)",
        summary:
          "This award will be won by a vendor, carrier, distributor, or service provider which has delivered a top-notch channel partner programme combining high-end touchpoints, training, portals, marketing campaigns etc. which have increased the status, value and growth prospects of resellers.",
        sponsor__title: "",
        sponsor: "../../../../img/sponsors/ans.webp",
        form: '../../../forms/best_partner_programme_above_10m.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "8x8",
              "Gamma",
              "Jola",
              "PXC",
              "SCG Together",
              "Spitfire Network Services",
              "Wildix",
              "Zest4",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Gamma"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Jola",
            ]
          }
        ]
      },
      {
        id: "best_channel_business_service_maintenance_finance_marketing_etc",
        subcategory: "Channel Support Category",
        name: "Best Channel Business Service",
        summary:
          "The Best Channel Business Service Award will be won by a company that has provided an exemplary support service to channel businesses across the training, marketing, installation, maintenance, recruitment, and telephony music/messaging on hold spectrums.",
        sponsor__title: "",
        sponsor: null,
        form: '../../../forms/best_channel_business_service.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Audio Productions",
              "Blabbermouth",
              "Channel Sales Academy",
              "GetCrisp",
              "IPNetix",
              "Kampfire by Obrecsys",
              "Layer Systems",
              "SER Recruitment",
              "Tekton Billing",
              "The Horsepower Partnership",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "GetCrisp",
              "The Horesepower Partnership"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Channel Sales Academy",
            ]
          }
        ]
      },
      {
        id: "best_channel_portal",
        subcategory: "Channel Support Category",
        name: "Best Channel Portal",
        summary:
          "This award will go to the vendor, distributor, carrier, or service provider with the best portal that enables channel partners to order, manage and process goods or services more efficiently, providing maximum control, increased visibility, and reduced overheads.",
        sponsor__title: "",
        sponsor: null,
        form: '../../../forms/best_channel_portal.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Comms365",
              "Eclipse Wholesale",
              "Fibre 1",
              "Fidelity Group",
              "Giacom",
              "Intermedia Cloud Communications",
              "Jola",
              "Tekton Billing",
              "Voip Unlimited",
              "Zest4",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Giacom"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Jola",
            ]
          }
        ]
      },
      {
        id: "best_reseller_innovation_award",
        subcategory: "Business Innovation Category",
        name: "Best Reseller Innovation Award",
        summary:
          "This award will go to the reseller business i.e., VAR, telecoms reseller, converged reseller, dealer, or MSP) which has introduced a new, innovative solution or product that has been successfully sold to end user customers.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/pxc.webp",
        form: '../../../forms/best_reseller_innovation_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Barclay Digital Services",
              "Cobalt Communications Solutions",
              "D R Communications (DRC)",
              "Fidelity Group",
              "Focus Group",
              "Redsquid",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Barclay Digital Services"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Fidelity Group",
            ]
          }
        ]
      },
      {
        id: "best_supplier_innovation_award",
        subcategory: "Business Innovation Category",
        name: "Best Supplier Innovation Award",
        summary:
          "This award will go to the supplier business i.e., vendor, carrier, service provider or distributor which has introduced a new, innovative solution or product that has helped resellers increase the value of their sales to end user customers.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/pxc.webp",
        form: '../../../forms/best_supplier_innovation_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Aryaka Networks",
              "CallCabinet",
              "Elisha Telecom",
              "GoBIG Online",
              "Inform Analytics",
              "Layer Systems",
              "NetXL",
              "Node4 Channel",
              "Salt Communications",
              "Virgin Media Business",
              "Voiceflex",
              "Xelion",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Inform Analytics",
              "Voiceflex"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "GoBIG Online",
            ]
          }
        ]
      },
      {
        id: "diversity_award",
        subcategory: "Positive Impact Category",
        name: "Diversity equity and inclusion (DEI) award",
        summary:
          "This award will go to the ICT industry business. i.e., a vendor, carrier, solutions provider, distributor, VAR, telecoms reseller, converged reseller, dealer, or MSP which has embraced diversity in a highly positive manner and has enabled people to develop their careers regardless of their gender, race, religion, sexual orientation, or disability.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/sky-business.webp",
        form: '../../../forms/diversity_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "Core Technology Systems",
              "Jola",
              "Layer Systems",
              "Support To Win",
              "Vorboss"
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Jola"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "Vorboss",
            ]
          }
        ]
      },
      {
        id: "sustainability_award",
        subcategory: "Positive Impact Category",
        name: "Sustainability Award",
        summary: "This award will go to the ICT industry business i.e., vendor, carrier, solutions provider, distributor, VAR, telecoms reseller, converged reseller, dealer, or MSP, which has initiated more sustainable use of natural resources and greatly reduced the environmental impact of its products or activities.",
        sponsor__title: "Sponsored By",
        sponsor: "../../../../img/sponsors/sky-business.webp",
        form: '../../../forms/sustainability_award.docx',
        finalists: [
          {
            year: "2024",
            company: [
              "BT Wholesale",
              "CallCabinet",
              "Evolve IP",
              "MS3 Networks",
              "PXC",
              "Redsquid",
              "Rydal Group",
              "XMA",
            ]
          }
        ],
        highly__commended: [
          {
            year: "2024",
            company: [
              "Redsquid"
            ]
          }
        ],
        winner: [
          {
            year: "2024",
            company: [
              "BT Wholesale",
            ]
          }
        ]
      },
    ],
  },
  // {
  //   order: 4,
  //   topic: "Special",
  //   summary:
  //     "Special Awards",
  //   categories: [
  //     {
  //       id: "comms_dealer_entrepreneur_of_the_year_award",
  //       subcategory: "Special",
  //       name: "Comms Dealer Entrepreneur of the Year Award",
  //       summary:
  //         "Special",
  //       sponsor__title: "",
  //       sponsor: "",
  //       form: '',
  //       finalists: [
  //         {
  //           year: "2024",
  //           company: [
  //             "",
  //           ]
  //         }
  //       ],
  //       highly__commended: [
  //         {
  //           year: "2024",
  //           company: ""
            
  //         }
  //       ],
  //       winner: [
  //         {
  //           year: "2024",
  //           company: [
  //             "Paul Bradford",
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: "reseller_of_the_year_award",
  //       subcategory: "Special",
  //       name: "Reseller of the Year Award",
  //       summary:
  //         "Special",
  //       sponsor__title: "",
  //       sponsor: "",
  //       form: '',
  //       finalists: [
  //         {
  //           year: "2024",
  //           company: [
  //             "",
  //           ]
  //         }
  //       ],
  //       highly__commended: [
  //         {
  //           year: "2024",
  //           company: ""
  //         }
  //       ],
  //       winner: [
  //         {
  //           year: "2024",
  //           company: [
  //             "Uplands OneTelco",
  //           ]
  //         }
  //       ]
  //     },
  //   ]
  // }
];

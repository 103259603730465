import { differenceInDays, parseISO } from 'date-fns';

/* Configuration Constants */
export const MAINTENANCE_MODE = false;
export const DEVELOPER_MODE = false;
export const FINALISTS = true;
export const WINNERS = true;

export const EVENT_URL = "https://cnawards.com";
export const LOCAL_URL = "http://localhost/thecnawards";
export const SITE_URL = DEVELOPER_MODE ? LOCAL_URL : EVENT_URL;

export const EVENT_NAME = "Comms National Awards";
export const EVENT_VENUE = "Park Plaza Hotel";
export const EVENT_ADDRESS = "Park Plaza Hotel, Westminster Bridge, London";
export const EVENT_SLOGAN = "The comms industry's most coveted awards extravaganza";
export const EVENT_REGISTRATION = "#";

/* Event Date Strings */
export const EVENT_DATE_STRING = "2025-09-25";
export const EVENT_ENTRY_DEADLINE_STRING = "2025-07-17";
export const EVENT_FINALISTS_DATE_STRING = "2025-07-28";
export const EVENT_ENTRIES_OPEN_DATE_STRING = "2025-05-20";

/* Current Year */
const today = new Date();
export const CURRENT_YEAR = today.getFullYear();

/* Date Objects for Event and Entry Deadline */
const eventDate = new Date(EVENT_DATE_STRING);
const entryDate = new Date(EVENT_ENTRY_DEADLINE_STRING);
const entryFinalistsDate = new Date(EVENT_FINALISTS_DATE_STRING);
const entryOpenDate = new Date(EVENT_ENTRIES_OPEN_DATE_STRING);

/* Event Date Formats */
export const EVENT_DATE = eventDate.toLocaleDateString('en-GB', {
  weekday: 'long',  // Thursday
  month: 'long',    // May
  day: '2-digit',   // 18
  year: 'numeric'   // 2023
});

export const ENTRY_DEADLINE = entryDate.toLocaleDateString('en-GB', {
  weekday: 'long',  // Thursday
  month: 'long',    // May
  day: '2-digit',
  year: 'numeric'
});

export const EVENT_YEAR = eventDate.toLocaleString('en-GB', {
  year: 'numeric'
});


// Previous Event Year
const previousYearDate = new Date(eventDate);
previousYearDate.setFullYear(eventDate.getFullYear() - 1);

export const PREVIOUS_EVENT_YEAR = previousYearDate.toLocaleString('en-GB', {
  year: 'numeric'  // 2023
});

/* Helper Functions */

// Generate cookie name
export const COOKIE_NAME = EVENT_NAME.replaceAll(' ', '_');


// Get ordinal suffix for a given day
function getOrdinalSuffix(day) {
  if (day % 10 === 1 && day !== 11) return 'st';
  if (day % 10 === 2 && day !== 12) return 'nd';
  if (day % 10 === 3 && day !== 13) return 'rd';
  return 'th';
}

// Create suffixed date string
function getSuffixedDateString(date) {
  const weekdayName = date.toLocaleDateString('en-GB', { weekday: 'long' });
  const monthName = date.toLocaleDateString('en-GB', { month: 'long' });
  const yearValue = date.toLocaleDateString('en-GB', { year: 'numeric' });
  const dayWithoutSuffix = date.toLocaleDateString('en-GB', { day: '2-digit' });

  const dayWithSuffix = `${dayWithoutSuffix}${getOrdinalSuffix(Number(dayWithoutSuffix))}`;
  return {
    suffixed: `${weekdayName} ${dayWithSuffix} ${monthName} ${yearValue}`,
    dayMonth: `${dayWithSuffix} ${monthName}`,
  };
}

/* Generate Suffixes and Day-Month Formats */
const eventDateStrings = getSuffixedDateString(eventDate);
const entryDeadlineStrings = getSuffixedDateString(entryDate);
const entryFinalistsDateStrings = getSuffixedDateString(entryFinalistsDate);
const entryOpenDateStrings = getSuffixedDateString(entryOpenDate);

export const EVENT_DATE_SUFFIXED = eventDateStrings.suffixed;
export const EVENT_DATE_DM = eventDateStrings.dayMonth;

export const ENTRY_DEADLINE_SUFFIXED = entryDeadlineStrings.suffixed;
export const ENTRY_DEADLINE_DM = entryDeadlineStrings.dayMonth;

export const EVENT_FINALISTS_DATE_SUFFIXED = entryFinalistsDateStrings.suffixed;
export const EVENT_FINALISTS_DATE_DM = entryFinalistsDateStrings.dayMonth;

export const EVENT_ENTRIES_OPEN_DATE_SUFFIXED = entryOpenDateStrings.suffixed;
export const EVENT_ENTRIES_OPEN_DATE_DM = entryOpenDateStrings.dayMonth;

/* Calculate Days Left */
export const calculateDaysLeft = (deadlineDateString) => {
  const deadlineDate = parseISO(deadlineDateString);
  const today = new Date();
  return differenceInDays(deadlineDate, today);
};
